import { VerticalAutoScroll } from './vertical-auto-scroll';
import { HorizontalAutoScroll } from './horizontal-auto-scroll';

import type { AutoScroll, AutoScrollOptions } from './auto-scroll';

type AutoScrollFactoryOptions = AutoScrollOptions & {
    plane?: 'vertical' | 'horizontal';
};

class AutoScrollFactory {
    create(element: HTMLElement, options: AutoScrollFactoryOptions = {}): AutoScroll {
        const { plane = 'horizontal', ...otherOptions } = options;

        if (plane === 'vertical') {
            return new VerticalAutoScroll(element, otherOptions);
        }

        return new HorizontalAutoScroll(element, otherOptions);
    }
}

const autoScroll = new AutoScrollFactory();

export { autoScroll };
