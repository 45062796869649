import { AutoScroll } from './auto-scroll';

class VerticalAutoScroll extends AutoScroll {
    isStartScroll() {
        return this.element.scrollTop === 0;
    }

    isEndScroll() {
        return (
            Math.ceil(this.element.scrollTop) ===
            this.element.scrollHeight - this.element.offsetHeight
        );
    }

    forwardScroll() {
        this.element.scrollTop += this.options.speed;
    }

    backwardScroll() {
        this.element.scrollTop -= this.options.speed;
    }
}

export { VerticalAutoScroll };
