import { AutoScroll } from './auto-scroll';

class HorizontalAutoScroll extends AutoScroll {
    isStartScroll() {
        return this.element.scrollLeft === 0;
    }

    isEndScroll() {
        return (
            Math.ceil(this.element.scrollLeft) ===
            this.element.scrollWidth - this.element.offsetWidth
        );
    }

    forwardScroll() {
        this.element.scrollLeft += this.options.speed;
    }

    backwardScroll() {
        this.element.scrollLeft -= this.options.speed;
    }
}

export { HorizontalAutoScroll };
