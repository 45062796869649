import Cookie from 'js-cookie';
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RestLink } from 'apollo-link-rest';
import { WebSocketLink } from '@apollo/client/link/ws';

import { GRAPHQL_URL, SOCKET_URL, REST_URL } from '@config/environment';
import { SystemHelper } from '@helpers';
import type {
    ApolloClientOptions as BaseApolloClientOptions,
    NormalizedCacheObject,
} from '@apollo/client';

type ApolloClientOptions = Omit<BaseApolloClientOptions<NormalizedCacheObject>, 'cache'>;

const isDev = SystemHelper.isDev();

const noopPromise = new Promise<void>(resolve => resolve());

const createApolloClient = (options: ApolloClientOptions = {}) => {
    const graphqlUri = GRAPHQL_URL;
    const socketUri = SOCKET_URL;
    const restUri = REST_URL;

    const httpLink = new HttpLink({ uri: graphqlUri });

    const wsLink =  new WebSocketLink({
        uri: socketUri,
        options: {
            reconnect: true
        }
    });

    const authHttpLink = setContext((_, prevContext) => {
        const headers = { ...prevContext.headers };

        const token = Cookie.get('accessToken');

        const context = {
            ...prevContext,
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : null,
            },
        };

        return context;
    });

    const restLink = new RestLink({
        uri: restUri,
        bodySerializers: {
            fileEncode: (data: any, headers: Headers) => {
                const formData = new FormData();

                formData.append('file', data, data.name);

                return { body: formData, headers };
            },
        },
    });

    // TODO: add to ApolloLink.from when backend can resolve it
    const batchHttpLink = new BatchHttpLink({
        uri: graphqlUri,
        batchMax: 6,
    });

    const link = split(
      ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
      },
      wsLink,
      httpLink,
    );

    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: isDev,
        ...options,
    });

    return apolloClient;
};

export { createApolloClient };
